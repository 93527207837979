import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "titan life" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "träningsutrustning-från-titan-life"
    }}>{`Träningsutrustning från Titan Life`}</h1>
    <p>{`Titan Life träningsutrustning levererar toppkvalitet för hemmaträning i Sverige, känt för en kompromisslös satsning på kvalitet, hållbarhet och användarvänlighet. Upptäck ett brett utbud av produkter som Titan Life viktskiva, hantlar, bänk, löpband och den innovativa spinningcykeln. Här får du en komplett lösning för styrka, kondition och hemmaträning som passar alla nivåer. Titan Life hemma träningsprodukter kombinerar stil och effektivitet för att hjälpa dig nå dina mål, oavsett om du precis börjat eller är en erfaren idrottare. Välj Titan Life för hållbar och tillförlitlig träningsutrustning skräddarsydd efter dina behov och ta din träning till nästa nivå.`}</p>
    <h2>Om Titan Life</h2>
    <p>Titan Life är en ledande aktör inom träningsutrustning för hemmabruk i Sverige, känd för sitt starka fokus på kvalitet, hållbarhet och användarvänlighet. Titan Life träningsutrustning är designad för att tillgodose behoven hos både nybörjare och erfarna träningsentusiaster. Genom att erbjuda ett brett utbud av produkter, har Titan Life etablerat sig som en pålitlig källa för högkvalitativ träningsutrustning, perfekt för att skapa ett effektivt och behagligt träningsprogram i hemmet. Med Titan Life som partner kan du förvänta dig produkter som stödjer både dina träningsmål och din långsiktiga hälsa.</p>
    <p>Företagets mål är att leverera produkter som sträcker sig över hela träningsspektrumet - från styrka och kondition till rehabilitering och återhämtning. Titan Life:s engagemang ligger i att erbjuda träningsutrustning som inte bara är funktionell, utan också estetiskt tilltalande och presterande. Genom att kombinera funktionalitet med stil, ger Titan Life sina kunder en komplett träningslösning, oavsett vilket område de vill fokusera på. Med Titan Life är du garanterad en effektiv och tillfredsställande träningsupplevelse hemma.</p>
    <h2>Viktskivor: Titan Life Pro-serien</h2>
    <p>Titan Life Pro Bumper Plate-serien står i en klass för sig när det kommer till viktskivor för hemmaträning. Med en robust gummibeläggning som klarar över 6000 droppar, är dessa viktskivor konstruerade för långvarig hållbarhet och säkerheten i fokus. Den skyddande gummiöverdraget bidrar inte bara till hållbarheten utan också till en tystare och mer behaglig träningsupplevelse genom att dämpa buller och minimera vibrationer. Vid varje lyft säkerställer Titan Life Pro Bumper Plates att ditt golv förblir intakt och skyddat, vilket frigör dig att fokusera på att nå dina träningsmål utan oro.</p>
    <p>Dessa premiumviktskivor är dessutom designade för att fungera sömlöst med standard olympiska skivstänger. Denna kompatibilitet gör Titan Life Pro Bumper Plates till ett mångsidigt val för alla typer av träningspass, oavsett om du precis börjat din lyftresa eller är en erfaren atlet. De erbjuder flexibilitet för en rad träningsrörelser, vilket gör dem till ett måste för både nybörjare och avancerade lyftare som söker kvalitet och prestanda i varje pass.</p>
    <h2>Hantlar och deras mångsidighet</h2>
    <p>Titan Life och Titan Life Pro Dumbbell-serierna är synonymt med gymkvalitet och komfort för träning hemma. Varje hantel i dessa serier är utformad med ett ergonomiskt lettrat handtag som ger ett optimalt grepp under alla dina träningspass, vilket innebär att du kan fokusera fullt ut på dina prestationer utan att oroa dig för säkerheten. Den robusta PU-beläggningen är en smart funktion som skyddar ditt golv från skador och oönskade märken, vilket gör att din träning förblir effektiv och ditt träningsutrymme snyggt. Titan Life hantlarna säkerställer inte bara funktionalitet utan också stil, och garanterar därmed en professionell träningskänsla i hemmiljön.</p>
    <p>Hantlarna i både Titan Life och Titan Life Pro-serierna säljs individuellt. Detta innebär att du kan bygga en skräddarsydd samling som passar just dina träningsbehov, oavsett om det handlar om muskelbyggande eller rehabilitering. Med denna möjlighet kan du enkelt anpassa och utöka ditt styrketräningsset efter dina specifika mål och preferenser. Den individuella försäljningen gör det enklare för dig att prova olika vikter och upptäcka vad som bäst stöder din träningsrutin. Välj Titan Life hantlar för att njuta av en flexibel och anpassningsbar träningsupplevelse, perfekt för alla nivåer av träning hemma.</p>
    <h2>Bänkar och hävräcken för maximal effektivitet</h2>
    <p>När det kommer till träningsutrustning som kombinerar funktion och hållbarhet, står Träningsbänk Titan Life Bench Flat Classic i en klass för sig. Denna bänk är speciellt konstruerad för att klara tuffa påfrestningar med en imponerande maximal belastningskapacitet på 300 kg, vilket gör den idealisk för både kommersiella gym och hemanvändare. Den kompakta designen (110 x 25 x 39 cm) gör den enkel att placera och använda, oavsett om du har gott om utrymme eller inte. Titan Life Bench Flat Classic erbjuder en stabil och pålitlig lösning för alla dina styrketräningsbehov, vilket ger träningspass med kvalitet och komfort.</p>
    <p>På samma sätt erbjuder hävräckena från Titan Life, som Multi Grip och Rack Pull Up, en mångsidig och robust träningsupplevelse. Dessa enheter är perfekta för den som vill förbättra sin överkroppsstyrka och arbetar med olika grepptekniker för att maximera träningsresultaten. Den robusta designen hos dessa hävräck gör dem särskilt lämpade för intensiv träning och de är byggda för att bära betydande viktbelastningar. Med Titan Life hävräck får du utrustning som inte bara optimerar din övningsprestanda utan också ger säkerhet och variation i träningen.</p>
    <h2>Cardioprodukter för en allsidig träning</h2>
    <p>Titan Life erbjuder högkvalitativa cardioprodukter som ger dig en fullständig konditionsträning hemma med produkter som löpbandet Titan Life T90 Pro Treadmill och spinningcykeln Indoor Bike S72. Löpbandet T90 Pro är utrustat med en kraftfull 3.0hk AC-motor och erbjuder upp till 25 olika träningsprogram, vilket gör det möjligt att skräddarsy varje träningspass för att passa dina specifika mål. Det generösa löpytan tillsammans med möjligheten att justera lutning upp till 22% säkerställer både komfort och utmaning i varje steg. Å andra sidan är Indoor Bike S72 utformad med ett magnetiskt motståndssystem som garanterar en tyst och jämn cykling, samtidigt som dess justerbara sadel och styre gör det lätt att justera för en perfekt personlig passform.</p>
    <p>Dessa cardiomaskiner erbjuder en komplett lösning för konditionsträning hemma, oavsett träningsnivå. Löpbandet ger särskilt nybörjare en explosion av valmöjligheter för varierad träning, medan mer avancerade användare kommer att uppskatta förmågan att öka intensiteten och olika programmöjligheter. Spinningcykeln tillåter smidiga övergångar mellan olika motståndsnivåer, vilket gör det enkelt att anpassa intensiteten och fokusera på olika konditionsmål. Titan Life träningsutrustning kombinerar prestanda och komfort för att uppfylla alla dina träningsbehov direkt i din hemmiljö.</p>
    <h2>Övrig utrustning för ett komplett hemmagym</h2>
    <p>TITAN LIFE PRO Power Bands är oumbärliga tillskott till ditt hemmagym, oavsett om du fokuserar på muskeluppbyggnad eller rehabilitering. Dessa kraftiga träningsband, med ett varierat motståndsspann från 7 kg upp till 80 kg, ger enastående flexibilitet för att anpassa träningen efter dina behov. Power Bands är särskilt projektade för att erbjuda stöd i styrkeövningar som bänkpress och deadlifts, samt för att underlätta återhämtningsträning som Pilates och rehabiliteringsrutiner. De olika motståndsnivåerna möjliggör gradvis progression och mångsidig användning, vilket gör dem till en central del av din träningsutrustning hemma. Med dessa multifunktionella band kan du uppnå dina träningsmål på ett effektivt och säkert sätt.</p>
    <p>För att ytterligare organisera och maximera utrymmet i ditt hemmagym, presenterar vi TITAN LIFE Ryggbänk Hyper Extension och Kettlebell Rack. Ryggbänken är designad för att erbjuda specialiserad träning för nedre ryggen, vilket förbättrar hållning och minskar risken för skador. Kettlebellstället, med sin justerbara höjd och höga kapacitet, är idealiskt för att hålla dina vikter prydligt organiserade och lättillgängliga. Dessa tillbehör, tillsammans med din övriga TITAN LIFE träningsutrustning, hjälper till att skapa en effektiv och inspirerande träningsmiljö, där du kan fokusera på att nå dina personliga konditionsmål med stil och ordning.</p>
    <h2>Välj rätt Titan Life-produkt för ditt gym</h2>
    <p>Att välja rätt Titan Life träningsutrustning är avgörande för att uppnå dina specifika träningsmål, oavsett om du är nybörjare eller en erfaren atlet. För dig som fokuserar på tyngdlyftning erbjuder Titan Life Pro Bumper Plates den perfekta kombinationen av hållbarhet och tyst prestanda, idealisk för allt från teknikträning till intensiva lyftpass. Om du letar efter mångsidiga alternativ att komplettera ditt hemmagym är Titan Life Multi-Functional Bänkar anpassningsbara för olika träningsformer, inklusive bänkpress och core-träning. För kardiovaskulär träning rekommenderas Löpband Titan Life T90 Pro och Titan Life Indoor Bike S72, vilka erbjuder en bred uppsättning program och justerbara inställningar för en personlig träningsupplevelse. Oavsett ditt fokus finns det en Titan Life-serie som passar dina behov och lyfter din träning till nästa nivå.</p>
    <p>Investering i Titan Life-träningsutrustning garanterar en pålitlig och högpresterande träningsupplevelse hemma. Produkterna är designade med kundanpassning i åtanke och erbjuder både stil och funktionalitet. Med Titan Life kan du njuta av premiumkvalitet som möter dina träningsmål, vilket gör varje pass roligare och mer effektivt. Upplev tryggheten i att investera i produkter som håller i längden, och som hjälper dig nå nya höjder i din träningsrutin.</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      